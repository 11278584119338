import { observer } from "mobx-react-lite";
import { globalStore } from "../../store";
import { Box, CircularProgress } from "@mui/material";
import { configure } from "mobx";

configure({
  enforceActions: 'never'
})

const Header = observer(() =>{
  return (
    <header>
      <h3>
        Dashboard จำลองสถานการณ์ปริมาณน้ำและปริมาณน้ำเข้า (Inflow) จ.ระยอง
      </h3>
      {/* {globalStore.loading ? 'Loading True' : 'Loading False'} */}
      {/* {globalStore.loading && <Box display="flex" justifyContent="center" alignItems="center">Loading <CircularProgress size={25} sx={{ marginLeft: '10px'}}/></Box> } */}
    </header>
  );
})

export default Header;
