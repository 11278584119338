import "./footer.css";
function Footer() {
  return (
    <footer className="footer">
      <p>
        Copyright: ©2024 All Rights Reserved by Corporate and SCGC BCM Office
      </p>
    </footer>
  );
}

export default Footer;
